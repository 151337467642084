import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next, Translation} from "react-i18next";

i18n.use(LanguageDetector).use(initReactI18next).init({
  debugger: true,
  lng: "nl", 
  fallbackLng: "en", 
  resources: {
    en: {
      translation: {
        header: {
          solution: "Solution",
          solution_1_title: "Cities and municipalities",
          solution_1_description: "Data-powered solutions for city waste management.",
          solution_2_title: "Private waste collectors",
          solution_2_description: "Leveraging data for private waste collection succes.",
          testimonial: "Testimonial",
          sales: "Contact sales",
          references: "References",
          about: "About us",
          contact: "Contact",
          login: "Log in"
        },
        footer: {
          expolore: "Explore",
          expolore_1: "References",
          expolore_2: "About us",
          expolore_3: "Contact",
          solution: "Solution",
          solution_1: "Cities & municipalities",
          solution_2: "Waste collectors",
          contact: "contact",
          contact_1: "info@rebin.be",
          contact_2: "Lammerstraat 13 9000, Ghent, Belgium",
          contact_3: "BE 0799.727.089",
          update: "Stay up to date",
          input: "Enter your email",
          cta_button: "Send"
        },
        homepage: {
          hero_span: "Streamline waste collection",
          hero_title: "The nr.1 platform for efficient waste collection",
          hero_subtitle: "Take control of your resources and explore how your waste collection can be optimized.",
          hero_cta_button: "Talk to Rebin",
          partner_title: "Trusted by leading organizations",
          solution_span: "Solutions",
          solution_title: "Discover our comprehensive offering",
          solution_1_title: "Fill level monitoring",
          solution_1_subtitle: "Monitor container levels and collect only when necessary.",
          solution_2_title: "Route optimisation",
          solution_2_subtitle: "Optimize collection routes and reduce time, and costs.",
          solution_3_title: "Data insights",
          solution_3_subtitle: "Gain actionable data insights for strategic decision-making.",
          why_choose_us_span: "Why choose us",
          why_choose_us_title: "Seamlessly integrate_every_waste asset",
          why_choose_us_description: "Discover the power of our versatile solution, transforming any bin or container into a smart one. Regardless of size or shape, our technology seamlessly integrates with every kind of waste asset.",
          testimonial_span: "Testimonials",
          testimonial_title: "Trusted by leading organizations",
          testimonial_cta_button: "Case studies",
          FAQ_span: "Frequently asked questions",
          FAQ_title: "Got questions? We've got answers!",
          FAQ_1_title: "General",
          FAQ_2_title: "Technology",
          FAQ_3_title: "Software platform",
          FAQ_General_1_title: "What is the cost of your solution?",
          FAQ_General_1_description: "Our solution is offered at a monthly price per smart waste asset, which covers both the use of the sensors and the software platform, along with a one-time installation/setup cost per bin/container. The exact price varies depending on project size, contact the Rebin team for more information.",
          FAQ_General_2_title: "Is smart waste collection applicable to my organisation?",
          FAQ_General_2_description: "Smart waste collection offers significant advantages to both rural municipalities with a small number of spread-out bins as well as to more densely populated cities. Private waste collectors handling various waste streams from public or industrial containers can also greatly benefit from the increased operational efficiency and improved quality of service to their clients. Beyond these two main categories, smart waste management proves valuable in many other contexts, such as large campuses, parks, and business districts. Reach out to the Rebin team to discover a tailored solution that meets your specific needs.",
          FAQ_General_3_title: "How does the implementation work?",
          FAQ_General_3_description: "The entire installation of the sensors and start-up of the system is carried out by the Rebin team in a period of 4 to 6 weeks after confirmation of purchase. After installation, an onboarding takes place in which the operation of the software platform is explained in detail.",
          FAQ_General_4_title: "Do you provide customer support after purchase?",
          FAQ_General_4_description: "Rebin guarantees optimal operation of the system throughout the whole contract duration, and therefore also troubleshoots in case of possible defects/problems.",
          FAQ_Technolgy_1_title: "How does the sensor work?",
          FAQ_Technolgy_1_description: "Rebin provides a battery-powered time-of-flight laser sensor that can accurately estimate the fill level based on distance measurements at multiple points, including on uneven surfaces. The sensor is no larger than a few centimetres in any dimension and can be placed in any type of bin/container.",
          FAQ_Technolgy_2_title: "What is the lifespan of a sensor?",
          FAQ_Technolgy_2_description: "The battery powers the sensor for a period of 4 to 8 years, depending on the desired number of fill level measurements per day. The battery in the sensor can be replaced at the end of their service life very conveniently, eliminating the need to purchase new sensors.",
          FAQ_Technolgy_3_title: "To which container and bin types is fill level monitoring applicable?",
          FAQ_Technolgy_3_description: "Thanks to the sensor's compact size and smart design, fill level monitoring with Rebin's smart sensor technology is applicable to any type of waste asset, ranging from small public bins to large (semi-)underground containers with a volume of 5000l or more.",
          FAQ_Platform_1_title: "Does the software platform have a high learning curve?",
          FAQ_Platform_1_description: "The software platform is deliberately set up to be very user-friendly, ensuring its use is very quickly grasped by any type of user. Moreover, the software platform is patiently gone over during the onboarding process, and the Rebin team is always available to answer any questions that may arise.",
          FAQ_Platform_2_title: "Doesn't the software platform complicate the collection process?",
          FAQ_Platform_2_description: "There is absolutely no need to move completely away from fixed collection times to enjoy the benefits of the system. Moreover, the platform allows automatic generation of collection routes at fixed times, but still based on actual fill levels. Hence, no manual action by a planner is required to create a collection route.",
          FAQ_Platform_3_title: "Won't I always be too late if I only collect full containers/bins?",
          FAQ_Platform_3_description: "It is certainly not the intention to collect only waste assets with a fill level of 100%. The percentage threshold from which a waste asset is considered 'full' can vary from container to container, depending on how fast it increases in fill level (e.g. 'full' above a fill level of 60% for rapidly increasing waste assets, and 80% for slowly increasing waste assets).",
          FAQ_Platform_4_title: "Should I collect every full bin/container in the same route?",
          FAQ_Platform_4_description: "When waste assets are located far away from each other, it is not always ideal to collect in the same route. By using 'regions', you can divide waste assets into self-determined geographical areas, and organise collection routes by region.",
          CTA_title_1: "Is your organization next?",
          CTA_title_2: "Start now.",
          CTA_button: "Contact us"

        },
        contactpage: {
          hero_span: "Contact", 
          hero_title: "Join our mission to make waste management more efficient",
          form_information: "Contact information",
          form_address_title: "Address",
          form_address_info: "Lammerstraat 13, 9000 Ghent, Belgium",
          form_touch: "Get in touch",
          form_name_title: "Full name",
          form_name_info: "Your full name",
          form_phone_title: "Phone",
          form_phone_info: "Your phone number",
          form_email_title: "Email",
          form_email_info: "Your email address",
          form_message_title: "Message",
          form_message_info: "Please include all relevant information",
          form_cta_button: "Send message",
        },
        aboutpage: {
          hero_span: "Our story", 
          hero_title: "How we started",
          hero_description: "Spotting an overflowing bin in the park, we decided to act. Recognizing inefficiencies in waste collection, we resolved to enhance the system, tackling overflowing bins and other asset challenges to benefit the environment and community.",
          statisticbar_stat1: "Collection _hours_ saved",
          statisticbar_stat2: "_Kilometers_ less covered",
          statisticbar_stat3: "Tons less _CO2_",
          statisticbar_stat4: "_Plastic_ bags avoided",
          mission_span: "Our mission",
          mission_title: "Empowering organisations with smart waste management solutions",
          mission_description: "Rebin enables businesses and governments to revolutionize waste management. With our innovative software, we provide real-time monitoring, streamline collection routes, and deliver actionable insights, one asset at a time.",
          team_span: "Our team",
          team_title: "Meet the Rebineers thriving for impact",
          team_cta: "Become a Rebineer",
          articles_span: "Articles",
          articles_title: "Rebin in the news",
          article1_title: "Maarkedal introduces smart waste sensors to save time and fuel in waste collection",
          article2_title: "The smart trash bin: from student project to the Port of Rotterdam (and the rest of the world?)",
          article3_title: "Asse becomes the fourth municipality in Flanders to install fill level sensors in public trash bins",
          article1_date: "October 22, 2023",
          article2_date: "October 22, 2024",
          article3_date: "October 9, 2023",
          news_article_span: "News article" 
        },
        citiespage: {
          hero_span: "Cities & municipalities",
          hero_title: "The all in-one-solution for public waste collection",
          hero_description: "Rebin provides real-time fill level monitoring, optimizes collection routes, and delivers actionable insights, one bin at a time.",
          hero_cta: "Book a demo",
          without_rebin_span: "Without Rebin",
          with_rebin_span: "With Rebin",
          benefits_span: "Benefits",
          benefits_title: "Discover why smart waste collection can help you",
          benefit1_title: "Detailed bin inventory",
          benefit1_description: "Map bins digitally and access an up-to-date inventory.",
          benefit2_title: "Cost savings",
          benefit2_description: "Reduce collection costs by eliminating unnecessary pick-ups.",
          benefit3_title: "Clean environment",
          benefit3_description: "Prevent overflowing bins and litter with real-time fill level insights.",
          benefit4_title: "Automated scheduling",
          benefit4_description: "Avoid scheduling difficulties with a adaptive planning tool.",
          benefit5_title: "Optimal bin placement",
          benefit5_description: "Use insights to identify redundant bins and optimize placement.",
          benefit6_title: "Legislation compliant",
          benefit6_description: "Meet the latest regulations through accessible data from our platform.",
          features_span: "Features",
          features_title: "Explore the features you need",
          feature1_label: "Dashboard",
          feature1_title: "Dashboard",
          feature1_subtitle: "Know the status of your bins",
          feature1_description: "Track the fill levels of your bins with a complete overview. Quickly identify critical KPIs and make informed decisions accordingly.",
          feature2_label: "Route optimisation",
          feature2_title: "Route optimisation",
          feature2_subtitle: "Bypass unnecessary stops",
          feature2_stat1: 'Reduced collection time',
          feature2_stat2: 'Fuel cost savings',
          feature2_description: "Improve collection operations by overlooking empty bins, saving costs and reducing CO2 emissions along the way. Tailor routes using up-to-date fill level information to avoid unnecessary staff deployment.",
          feature3_label: "Analytics",
          feature3_title: "Analytics",
          feature3_subtitle: "Take strategic decisions",
          feature3_description: "Access a variety of statistics, such as collection frequencies, waste amounts, and fill level evolutions. Utilize data-driven insights to streamline resource management and reporting.",
        },
        collectorpage: {
          hero_span: "Our story", 
          hero_title: "How we started",
          hero_description: "Whether you are focused on optimizing your operations, improving service quality, or gaining a competitive edge, Rebin’s solution is there to support your success.",
          hero_cta_button:"Book a demo",
          feature_1_title: "Route optimisation",
          feature_1_description: "Enhance profitability by avoiding empty pick-ups.",
          feature_2_title: "Timely waste collection",
          feature_2_description: "Guarantee timely collection and put an end to overflowing assets.",
          feature_3_title: "Collection efficiency",
          feature_3_description: "Increase material collection with the same resources.",
          solution_span: "Solution",
          solution_title: "The route to waste management efficiency",
          solution_1_title: "Fill level monitoring",
          solution_1_description: "Stay informed about container fill levels with customizable notifications and an intuitive dashboard. Avoid both overflow and unnecessary collection of waste assets.",
          solution_2_title: "Optimized collection operations",
          solution_2_description: "Improve collection processes using both real-time and predictive fill level data. Optimize routes with a fully automated planning tool based on available drivers, vehicle capacities, and relevant geographical regions.",
          solution_3_title: "Comprehensive data insights",
          solution_3_description: "Gain insights into waste streams at both a global level and a sub-level by customer or region. Uncover trends and evolutions in the operational as well as the financial aspects of your collection process.",
          benefits_span: "Benefits",
          benefits_title: "The key to waste collection success",
          benefits_1_title: "Optimized fleet planning",
          benefits_1_description: "Align your process with fleet availability and load capacity to avoid interrupted routes due to fully loaded trucks.",
          benefits_2_title: "Operational efficiency",
          benefits_2_description: "Cut collection costs by eliminating unnecessary pick-ups, ensuring cost-effective operations.",
          benefits_3_title: "Improved service",
          benefits_3_description: "Fully relieve customers with a hassle-free logistics process by removing notification needs and avoid overflow.",
          benefits_4_title: "Maximized volumes",
          benefits_4_description: "Increase volumes and revenues by collecting valuable waste streams like glass and textiles on time.",
        },
        referencespage: {
          references_title: "Embracing the_future_of smart waste management",
          references_span: "Our references",
          references_subtitle: "Latest succes stories",
          blogcard3_title: "Streamlining waste management in Europe's largest port",
          blogcard3_span: "Technology",
          blogcard3_date: "January 10, 2025",
          blogcard2_title: "Rebin helps Moorslede become legislation compliant",
          blogcard2_span: "Technology",
          blogcard2_date: "July 29, 2024",
          blogcard1_title: "Optimizing waste collection in Maarkedal",
          blogcard1_span: "Technology",
          blogcard1_date: "March 25, 2024",
          references_summary: "Summary",
          maarkedal_title: "Optimizing waste collection in municipality Maarkedal with smart waste sensors",
          maarkedal_text1: "Maarkedal, a rural municipality nestled in the heart of the Flemish Ardennes, experiences significant variation in the utilization of public bins across different locations. While tourist activity surges during the summer months, attracting hiking and cycling enthusiasts, winters tend to bring a quieter atmosphere. This seasonal fluctuation, alongside differing usage patterns among various areas, poses a challenge for efficient waste management within Maarkedal. Unfortunately, the lack of comprehensive data hinders an effective response from Maarkedal's authorities.",
          maarkedal_text2: "Frank Maes, head of the municipality's technical department, finds himself grappling with this issue. \"Every week, my team is tasked with emptying the bins, totaling 73 across the municipality. However, due to the lack of prior knowledge regarding which bins require attention, we must inspect each one during our collection routes. This results in unnecessary expenditure of work hours, further compounded by our existing shortage of manpower.\" Mayor Joris Nachtergaele is well aware of this challenge and sought assistance from Rebin.",
          maarkedal_text3: "Rebin installed sensors in each bin, capable of measuring fill levels, and provided access to their software platform. A few months after implementation, the benefits were already apparent. \"By strategically avoiding unnecessary stops at nearly empty or partially filled bins, we have achieved a 40% reduction in kilometers traveled and up to 50% less time spent on collection routes,\" noted Joris. \"Moreover, our previous approach often led to overflowing bins, resulting in litter accumulation and resident complaints. By collaborating with Rebin, we can preemptively address bin overflow issues. In summary, this smart system offers numerous advantages and potential.\"",
          maarkedal_quote: "A smart system, with a lot of advantages and potential.",
          maarkedal_summary1: "Maarkedal faces waste management challenges due to varying bin usage",
          maarkedal_summary2: "Rebin monitors fill levels and optimizes collection routes",
          maarkedal_summary3: "Significant time and cost savings, and no more overflowing bins",
          moorslede_title: "Fill level data helps Moorslede stay compliant with waste management legislation",
          moorslede_text1: "Managing public waste is a significant challenge for nearly every local government, and Moorslede in Belgium, is no exception. Ensuring the availability of sufficient bins and timely emptying or replacement is a complex task, further complicated by regional regulations. In Flanders, every local government must develop a comprehensive waste bin management plan by 2030. This plan requires creating an inventory of waste bins, monitoring fill levels, and implementing necessary reporting, analysis, and adjustments.",
          moorslede_text2: "To address these challenges, Moorslede has embraced an innovative solution by partnering with Rebin. Rebin has installed 58 sensors across the municipality to enhance waste management efficiency. These sensors monitor the fill levels and usage of waste bins, providing real-time data that allows for timely and precise interventions.",
          moorslede_text3: "With real-time monitoring of public bins, waste collections become more efficient, and local governments can precisely identify areas where bins are needed or where there are excess. This ensures that waste management plans are always up-to-date, compliance with legislation is maintained, and attention can be focused on addressing problem areas.",
          moorslede_quote: "Rebin's solution saves us valuable time, automates collection, and ensures we remain compliant with regulations.",
          moorslede_summary1: "Moorslede struggles with managing waste bins and meeting Flanders' 2030 regulations",
          moorslede_summary2: "Rebin provided an up-to-date bin inventory and installed fill level sensors in every bin",
          mmoorslede_summary3: "Result: optimized collections, accurate bin management, and compliance with regulations",
          rotterdam_title: "Streamlining waste management in Europe’s largest port",
          rotterdam_text1: "As the largest port in Europe, the Port of Rotterdam spans an enormous area of over 12,000 hectares. Maintaining this vast expanse is a complex challenge, as Pieter Van Driel, project manager at Idverde—a company responsible for the port’s upkeep for many years—can attest. “Beyond maintaining greenery, emptying waste containers is one of the most time-consuming tasks we handle,” Pieter explains. The port area houses approximately 250 waste bins, distributed across its expansive grounds. “Although we often encountered empty bins during our collection routes, we absolutely want to prevent overflowing bins that could lead to litter,” he adds. This logistical challenge took a turn in spring 2024, when Pieter reached out to Rebin.",
          rotterdam_text2: "Since partnering with Rebin, every waste bin in the port has been equipped with a sensor. These sensors provide real-time data on bin fill levels, allowing the team to know precisely which bins need attention and, equally importantly, which ones don’t. Besides insights into fill levels, the planning tool on Rebin's platform has brought significant advantages to Idverde’s operations. Pieter explains, “Of course, if only a few bins are full but they are scattered dozens of kilometers apart, it would be far from efficient to collect them all in the same collection route. By organizing our work by regions, we maintain a structured approach while saving substantial resources.” Furthermore, Rebin’s platform allows routes to be generated automatically—daily, weekly, or as needed—without requiring manual inputs. Pieter emphasizes the convenience this brings: “The automated route generation is very beneficial and saves us a lot of time and trouble.”",
          rotterdam_text3: "One of the aspects Pieter values most about Rebin is the interactions with its team. “The Rebin team is always accessible and open to feedback, which helps them continuously innovate. This aligns perfectly with our vision at Idverde.” Looking ahead, Idverde is exploring ways to implement Rebin’s system in additional projects, further optimizing their operations.",
          rotterdam_quote: "The automated route generation is very beneficial and saves us a lot of time and trouble.",
          rotterdam_summary1: "Rebin equipped 250 bins in the Port of Rotterdam with sensors to monitor real-time fill levels",
          rotterdam_summary2: "Rebin's platform automates waste collection routes by region, saving time and resources",
          rotterdam_summary3: "The system is being explored for use in other projects of Idverde to further optimize operations",
        }
      }
    },
    nl: {
      translation: {
        header: {
          solution: "Oplossing",
          solution_1_title: "Steden en Gemeenten",
          solution_1_description: "Data gedreven oplossingen voor stadsafvalbeheer.",
          solution_2_title: "Private afvalophalers",
          solution_2_description: "Het benutten van data voor efficiënte afvalinzameling.",
          testimonial: "Testimonial",
          sales: "Contacteer Rebin",
          references: "Referenties",
          about: "Over ons",
          contact: "Contact",
          login: "Log in"
        },
        footer: {
          explore: "Ontdek",
          explore_1: "Referenties",
          explore_2: "Over ons",
          explore_3: "Contact",
          solution: "Oplossing",
          solution_1: "Steden & gemeenten",
          solution_2: "Afval inzamelaars",
          contact: "contact",
          contact_1: "info@rebin.be",
          contact_2: "Lammerstraat 13, 9000 Gent, België",
          contact_3: "BE 0799.727.089",
          update: "Blijf op de hoogte",
          input: "Typ jouw email",
          cta_button: "Verstuur"
        },
        homepage: {
          hero_span: "Stroomlijn afvalophaling",
          hero_title: "Het nr.1 platform voor efficiënte afvalophaling",
          hero_subtitle: "Behoud controle over tijd en middelen en ontdek hoe jouw afvalophaling kan worden geoptimaliseerd.",
          hero_cta_button: "Contacteer Rebin",
          partner_title: "Vertrouwd door toonaangevende organisaties",
          solution_span: "Oplossingen",
          solution_title: "Ontdek ons allesomvattende aanbieding",
          solution_1_title: "Vulgraad monitoring",
          solution_1_subtitle: "Monitor vulgraden van containers in real-time en haal enkel op wanneer nodig.",
          solution_2_title: "Route optimalisatie",
          solution_2_subtitle: "Optimaliseer inzamelroutes van jouw organisatie en verminder tijd, kosten en uitstoot.",
          solution_3_title: "Data inzichten",
          solution_3_subtitle: "Verkrijg bruikbare data-inzichten en neem strategische beslissingen.",
          why_choose_us_span: "Waarom voor ons kiezen",
          why_choose_us_title: "Integreer naadloos_elk_type container",
          why_choose_us_description: "Ontdek de kracht van onze veelzijdige oplossing, die eenvoudig elke afvalbak of container tot ‘slim’ promoveert. Ongeacht de grootte of vorm, onze technologie integreert naadloos met elk type container.",
          testimonial_span: "Testimonials",
          testimonial_title: "Vertrouwd door verschillende organisaties",
          testimonial_cta_button: "Referenties",
          FAQ_span: "Frequently asked questions",
          FAQ_title: "Vragen? Wij hebben antwoorden!",
          FAQ_1_title: "Algemeen",
          FAQ_2_title: "Technologie",
          FAQ_3_title: "Platform",
          FAQ_General_1_title: "Wat zijn de kosten van jullie oplossing?",
          FAQ_General_1_description: "Onze oplossing wordt aangeboden tegen een maandelijkse prijs per slimme afvalbak/container, die zowel het gebruik van de sensoren als het softwareplatform omvat, samen met een eenmalige installatiekost per afvalbak/container. De exacte prijs varieert afhankelijk van de omvang van het project. Neem contact op met het Rebin-team voor meer informatie.",
          FAQ_General_2_title: "Is slimme afvalinzameling toepasbaar voor mijn organisatie?",
          FAQ_General_2_description: "Slimme afvalinzameling biedt aanzienlijke voordelen voor zowel landelijke gemeenten met een klein aantal verspreide afvalbakken als voor dichtbevolkte steden. Ook private afvalinzamelaars die verschillende afvalstromen verwerken uit openbare of industriële containers, kunnen sterk profiteren van de verhoogde operationele efficiëntie en verbeterde servicekwaliteit naar hun klanten toe. Naast deze twee hoofdgroepen is slim afvalbeheer waardevol in vele andere contexten, zoals grote campussen, parken, en domeinen. Neem contact op met het Rebin-team om een oplossing te ontdekken die aansluit bij jouw specifieke behoeften.",
          FAQ_General_3_title: "Hoe verloopt de implementatie?",
          FAQ_General_3_description: "De volledige installatie van de sensoren en de opstart van het systeem wordt uitgevoerd door het Rebin-team binnen een periode van 4 tot 6 weken na bevestiging van aankoop. Na de installatie vindt er een onboarding plaats waarin de werking van het softwareplatform uitgebreid wordt uitgelegd.",
          FAQ_General_4_title: "Bieden jullie klantenondersteuning na aankoop?",
          FAQ_General_4_description: "Rebin garandeert een optimale werking van het systeem gedurende de volledige looptijd van het contract en biedt ondersteuning bij eventuele defecten of problemen.",
          FAQ_Technolgy_1_title: "Hoe werkt de sensor?",
          FAQ_Technolgy_1_description: "Rebin levert een batterijgevoede time-of-flight-lasersensor die het vulniveau nauwkeurig kan bepalen op basis van afstandsmetingen met meerdere punten, zelfs bij oneffen oppervlakken. De sensor is niet groter dan enkele centimeters in elke dimensie en kan in elk type afvalbak of container worden geplaatst.",
          FAQ_Technolgy_2_title: "Wat is de levensduur van een sensor?",
          FAQ_Technolgy_2_description: "De batterij van de sensor levert energie voor een periode van 4 tot 8 jaar, afhankelijk van het gewenste aantal metingen per dag. Aan het einde van de levensduur kan de batterij in de sensor zeer eenvoudig worden vervangen, waardoor het niet nodig is om nieuwe sensoren aan te schaffen.",
          FAQ_Technolgy_3_title: "Voor welke types containers en afvalbakken is vulgraadmonitoring toepasbaar?",
          FAQ_Technolgy_3_description: "Dankzij de compacte grootte en het praktische ontwerp van de sensor, is vulgraadmonitoring met de slimme sensortechnologie van Rebin toepasbaar op elk type container, variërend van kleine openbare afvalbakken tot grote (semi-)ondergrondse containers met een volume van 5000 liter of meer.",
          FAQ_Platform_1_title: "Heeft het softwareplatform een steile leercurve?",
          FAQ_Platform_1_description: "Het softwareplatform is zeer gebruiksvriendelijk opgesteld, waardoor elk type gebruiker het heel snel onder de knie krijgt. Bovendien wordt het softwareplatform tijdens het onboardingproces zorgvuldig uitgelegd en is het Rebin-team altijd beschikbaar om eventuele vragen te beantwoorden.",
          FAQ_Platform_2_title: "Maakt het softwareplatform het inzamelproces niet ingewikkelder?",
          FAQ_Platform_2_description: "Er is absoluut geen behoefte om volledig af te stappen van vaste ophaalmomenten om te profiteren van de voordelen van het systeem. Bovendien laat het platform toe om automatisch inzamelroutes te genereren op vaste, zelfgekozen, tijdstippen, maar nog steeds op basis van de actuele vulniveaus. Dit betekent dat er geen handmatige actie van een planner nodig is om een inzamelroute te creëren.",
          FAQ_Platform_3_title: "Ben ik niet steeds te laat als ik enkel volle containers/afvalbakken ga ophalen?",
          FAQ_Platform_3_description: "Het is zeker niet de bedoeling om alleen containers met een vulgraad van 100% in te zamelen. De vulgraad-drempel waarboven een container als 'vol' wordt beschouwd, kan variëren per container afhankelijk van hoe snel het vulniveau toeneemt (bijv. 'vol' boven een vulgraad van 60% voor snel toenemende containers, en 80% voor langzaam toenemende containers).",
          FAQ_Platform_4_title: "Moet ik elke volle afvalbak/container in eenzelfde route gaan ophalen?",
          FAQ_Platform_4_description: "Wanneer containers ver van elkaar verwijderd zijn, is het niet altijd ideaal om deze in eenzelfde route op te halen. Door gebruik te maken van ‘regio’s’ kun je containers opdelen in zelfgekozen, geografische gebieden en inzamelroutes per regio organiseren.",
          CTA_title_1: "Is jouw organisatie de volgende?",
          CTA_title_2: "Start nu.",
          CTA_button: "Contacteer ons"
        },
        contactpage: {
          hero_span: "Contacteer ons",
          hero_title: "Sluit je aan bij onze missie om afvalbeheer efficiënter te maken",
          form_information: "Contact informatie",
          form_address_title: "Adres",
          form_address_info: "Lammerstraat 13, 9000 Gent, België",
          form_touch: "Neem contact op",
          form_name_title: "Volledige naam",
          form_name_info: "Jouw volledige naam",
          form_phone_title: "Gsm-nummer",
          form_phone_info: "Jouw gsm-nummer",
          form_email_title: "Email",
          form_email_info: "jouw email-adres",
          form_message_title: "Bericht",
          form_message_info: "Vermeld alle relevante informatie",
          form_cta_button: "Verstuur bericht",
        },
        aboutpage: {
          hero_span: "Ons verhaal",
          hero_title: "Hoe het begon",
          hero_description: "Tijdens een wandeling in het park zagen we een overvolle afvalbak en besloten we actie te ondernemen. Na onderzoek ontdekten we inefficiënties binnen afvalinzameling en bundelden we onze krachten om dit probleem aan te pakken.",
          statisticbar_stat1: "_Arbeidsuren_uitgespaard",
          statisticbar_stat2: "_Kilometer_minder afgelegd",
          statisticbar_stat3: "Ton minder_CO2_",
          statisticbar_stat4: "_Afvalzakken_vermeden",
          mission_span: "Onze missie",
          mission_title: "Organisaties versterken met slimme oplossingen voor afvalbeheer",
          mission_description: "Rebin stelt bedrijven en overheden in staat om afvalbeheer te transformeren. Met onze innovatieve software bieden we real-time monitoring, optimaliseren we inzamelroutes, en leveren we bruikbare data-inzichten, op niveau van iedere afvalbak of container.",
          team_span: "Ons team",
          team_title: "Ontmoet de Rebineers strevend naar impact",
          team_cta: "Word een Rebineer",
          articles_span: "Artikels",
          articles_title: "Rebin in het nieuws",
          article1_title: "Maarkedal zet slimme vuilnisbakken in om te besparen op tijd en brandstof van vuilniswagens",
          article2_title: "De slimme vuilnisbak: van studentenproject naar de haven van Rotterdam (en de rest van de wereld?)",
          article3_title: "Asse plaatst als vierde gemeente in Vlaanderen vulgraadsensoren in publieke vuilnisbakken",
          article1_date: "22 oktober, 2023",
          article2_date: "22 oktober, 2024",
          article3_date: "9 oktober, 2023",
          news_article_span: "Nieuws artikel" 
        }, 
        citiespage: {
          hero_span: "Steden & gemeenten",
          hero_title: "De complete oplossing voor publieke afvalophaling",
          hero_description: "Rebin biedt real-time vulgraadmonitoring, optimaliseert inzamelroutes, en levert bruikbare inzichten, voor elke afvalbak.",
          hero_cta: "Boek een demo",
          without_rebin_span: "Zonder Rebin",
          with_rebin_span: "Met Rebin",
          benefits_span: "Voordelen",
          benefits_title: "Ontdek waarom slimme afvalophaling jouw stad kan helpen",
          benefit1_title: "Digitaal vuilnisbakkenplan",
          benefit1_description: "Breng afvalbakken in kaart en beschik over een actueel inventaris.",
          benefit2_title: "Kostenbesparing",
          benefit2_description: "Verlaag inzamelkosten door onnodige ledigingen te vermijden.",
          benefit3_title: "Propere leefomgeving",
          benefit3_description: "Voorkom uitpuilende afvalbakken en zwerfvuil dankzij live vulniveaus.",
          benefit4_title: "Automatische planning",
          benefit4_description: "Voorkom planningsmoeilijkheden met een adaptieve planningstool.",
          benefit5_title: "Waardevolle inzichten",
          benefit5_description: "Identificeer overbodige afvalbakken en optimaliseer plaatsing.",
          benefit6_title: "Wettelijk conform",
          benefit6_description: "Voldoe aan de voorwaarden uit het ‘Lokaal Materialenplan 2030’.",
          features_span: "Features",
          features_title: "Ontdek features op jouw maat",
          feature1_label: "Dashboard",
          feature1_title: "Dashboard",
          feature1_subtitle: "Ken steeds de status van jouw afvalbakken",
          feature1_description: "Volg de real-time vulniveaus van afvalbakken in een gebruiksvriendelijk overzicht. Identificeer snel cruciale KPI's en neem op basis daarvan weloverwogen beslissingen.",
          feature2_label: "Route optimalisatie",
          feature2_title: "Route optimalisatie",
          feature2_subtitle: "Vermijd onnodige stops",
          feature2_stat1: 'Snellere inzamelrondes',
          feature2_stat2: 'Minder brandstofkosten',
          feature2_description: "Verbeter inzamelprocessen door lege afvalbakken over te slaan, en zo kosten te besparen en CO2-uitstoot te verminderen. Optimaliseer routes met up-to-date vulgraden om onnodige inzet van personeel te voorkomen.",
          feature3_label: "Statistieken",
          feature3_title: "Statistieken",
          feature3_subtitle: "Neem strategische beslissingen ",
          feature3_description:"Krijg toegang tot diverse statistieken, zoals ophaalfrequenties, afvalhoeveelheden en evoluties van vulniveaus. Gebruik data-gedreven inzichten om de plaatsing van afvalbakken te optimaliseren en rapportage te automatiseren.",
        },
        collectorpage: {
          hero_span: "Afvalophalers", 
          hero_title: "Stroomlijn inzamel-processen van afval",
          hero_description: "Of je nu processen optimaliseert, klantenservice verbetert of een voorsprong wilt, Rebin ondersteunt jouw succes.",
          hero_cta_button:"Boek een demo",
          feature_1_title: "Route optimalisatie",
          feature_1_description: "Verhoog winstgevendheid door lege ophalingen te vermijden.",
          feature_2_title: "Tijdige afvalophaling",
          feature_2_description: "Garandeer tijdige ledigingen en voorkom overvolle containers.",
          feature_3_title: "Efficiënte inzameling",
          feature_3_description: "Verhoog afvalinzameling met dezelfde middelen.",
          solution_span: "Oplossing",
          solution_title: "De weg naar efficiënter afvalbeheer",
          solution_1_title: "Vulgraadmonitoring",
          solution_1_description: "Wees steeds op de hoogte van vulniveaus van containers dankzij personaliseerbare notificaties en een intuïtief dashboard. Voorkom zowel overvolle containers als de onnodige inzameling van lege containers.",
          solution_2_title: "Geoptimaliseerde afvalinzameling",
          solution_2_description: "Verbeter inzamelingsprocessen met behulp van zowel actuele- als voorspellende vulniveaudata. Optimaliseer routes met een volledig geautomatiseerde planningstool rekening houdend met beschikbare chauffeurs, voertuigcapaciteiten, en relevante geografische regio's.",
          solution_3_title: "Uitgebreide data-inzichten",
          solution_3_description: "Krijg inzicht in afvalstromen op zowel globaal niveau als op subniveau per klant of regio. Ontdek trends en evoluties in zowel de operationele als de financiële aspecten van inzamelingsprocessen.",
          benefits_span: "Voordelen",
          benefits_title: "De weg naar succesvolle afvalinzameling",
          benefits_1_title: "Optimale fleetplanning",
          benefits_1_description: "Houd rekening met beschikbaarheid en laadcapaciteit van het wagenpark om onderbroken routes door volgeladen vrachtwagens te voorkomen.",
          benefits_2_title: "Operationele efficiëntie",
          benefits_2_description: "Reduceer inzamelingskosten door onnodige ophalingen te vermijden en kostenefficiënte processen te garanderen.",
          benefits_3_title: "Verbeterde klantenservice",
          benefits_3_description: "Ontzorg klanten volledig met een feilloos logistiek proces dat manuele volmeldingen overbodig maakt en overvolle containers vermijdt.",
          benefits_4_title: "Maximale inzamelvolumes",
          benefits_4_description: "Vergroot inzamelvolumes en inkomsten door waardevolle afvalstromen zoals glas en textiel tijdig op te halen.",
        },
        referencespage: {
          references_title: "Ontdek de_toekomst_van duurzaam afvalbeheer",
          references_span: "Onze referenties",
          references_subtitle: "Laatste succesverhalen",
          blogcard3_title: "Optimaliseren van afvalbeheer in de grootste haven van Europa",
          blogcard3_span: "Technologie",
          blogcard3_date: "10 januari, 2025",
          blogcard2_title: "Moorslede ondersteunen met het Lokaal Materialenplan",
          blogcard2_span: "Technologie",
          blogcard2_date: "29 juli, 2024",
          blogcard1_title: "Optimaliseren van afvalinzameling in Maarkedal",
          blogcard1_span: "Technologie",
          blogcard1_date: "25 maart, 2024",
          references_summary: "Samenvatting",
          maarkedal_title: "Optimaliseren van afvalinzameling in Maarkedal met slimme vulgraadsensoren",
          maarkedal_text1: "Maarkedal, een landelijke gemeente in het hart van de Vlaamse Ardennen, ervaart een aanzienlijke variatie in het gebruik van openbare vuilnisbakken op verschillende locaties. Terwijl de toeristische activiteit tijdens de zomermaanden sterk toeneemt en wandel- en fietsliefhebbers aantrekt, is het 's winters meestal rustiger. Deze seizoensfluctuatie vormt, samen met de variërende gebruikspatronen in de verschillende gebieden, een uitdaging voor efficiënt afvalbeheer in Maarkedal. Helaas staat een gebrek aan data een effectieve reactie van de Maarkedalse autoriteiten in de weg.",
          maarkedal_text2: "Frank Maes, hoofd van de technische dienst van Maarkedal, worstelt met dit probleem. “Elke week is mijn team verantwoordelijk voor het ledigen van de 73 vuilnisbakken verspreid over de gemeente. Omdat we van tevoren niet weten welke afvalbakken lediging nodig hebben, moeten we ze allemaal inspecteren tijdens onze ophaalroutes. Dit resulteert in onnodige arbeidsuren, terwijl we al kampen met een tekort aan mankracht.” Ook burgemeester Joris Nachtergaele is zich bewust van deze uitdaging en riep de hulp in van Rebin.",
          maarkedal_text3: "Rebin installeerde sensoren in elke afvalbak, die het vulniveau nauwkeurig meten, en gaf toegang tot hun softwareplatform. Enkele maanden na de implementatie waren de voordelen al duidelijk zichtbaar. “Door strategisch onnodige stops bij (quasi) lege afvalbakken te vermijden, hebben we een reductie van 40% in het aantal gereden kilometers behaald en tot 50% minder tijd besteed aan de afvalinzameling,” merkte Joris op. “Bovendien leidde onze vorige aanpak vaak tot overvolle afvalbakken, wat resulteerde in zwerfafval en klachten van inwoners. Door samen te werken met Rebin kunnen we deze problematiek proactief aanpakken. Samenvattend biedt dit slimme systeem dus tal van voordelen en potentieel.”",
          maarkedal_quote: "Een slim systeem, met heel wat voordelen en potentieel.",
          maarkedal_summary1: "Maarkedal kampt met uitdagingen in hun afvalbeheer door variërend gebruik van publieke vuilnisbakken",
          maarkedal_summary2: "Rebin monitort de vulniveaus en optimaliseert inzamelroutes",
          maarkedal_summary3: "Aanzienlijke tijd- en kostenbesparingen, en geen overvolle vuilnisbakken meer",
          moorslede_title: "Vulgraadmonitoring helpt Moorslede in lijn te blijven met eisen van Lokaal Materialenplan",
          moorslede_text1: "Het beheer van openbare afvalbakken is een grote uitdaging voor vrijwel elk lokaal bestuur, en het West-Vlaamse Moorslede is daarop geen uitzondering. Het voorzien van voldoende afvalbakken, en het tijdig ledigen of vervangen ervan is een complex gegeven, dat verder wordt bemoeilijkt door Vlaamse regelgeving. In Vlaanderen moet elke lokale overheid tegen 2030 een uitgebreid vuilnisbakkenplan ontwikkelen. Dit plan vereist het opstellen van een inventaris van de publieke afvalbakken, het monitoren van vulniveaus, en het uitvoeren van de noodzakelijke rapportages, analyses, en aanpassingen.",
          moorslede_text2: "Om deze uitdagingen aan te pakken, sloeg Moorslede de handen in elkaar met Rebin. Rebin plaatste sensoren verspreid over de 58 afvalbakken in de gemeente met als doel de afvalinzameling te optimaliseren. De sensoren monitoren de vulniveaus en het gebruik van de afvalbakken, waardoor gegevens beschikbaar zijn voor tijdige en gerichte inzamelroutes.",
          moorslede_text3: "Dankzij real-time vulgraadmonitoring van openbare afvalbakken kan de afvalinzameling een pak efficiënter georganiseerd worden. Bovendien geeft het lokale overheden de kans om precies identificeren waar additionele afvalbakken nodig zijn, of waar ze net overbodig zijn. Dit zorgt ervoor dat het vuilnisbakkenplan steeds relevant blijft, aan de eisen van het ‘Lokaal Materialenplan 2030’ voldaan is, en de aandacht gericht kan worden op het aanpakken van probleemgebieden.",
          moorslede_quote: "De oplossing van Rebin bespaart ons waardevolle tijd, automatiseert de inzameling, en zorgt ervoor dat we steeds voldoen aan de eisen van het Lokaal Materialenplan.",
          moorslede_summary1: "Moorslede heeft moeite met het beheer van de publieke afvalbakken en de eisen omtrent afvalbeheer van het Lokaal Materialenplan",
          moorslede_summary2: "Rebin zorgde voor een actuele inventaris van de afvalbakken en voorzag elke afvalbak van een vulgraadsensor",
          moorslede_summary3: "Resultaat: geoptimaliseerde inzamelroutes, effectief beheer van afvalbakken, en naleving van het Lokaal Materialenplan",
          rotterdam_title: "Optimaliseren van afvalbeheer in de grootste haven van Europa",
          rotterdam_text1: "Als grootste haven van Europa bestrijkt de Haven van Rotterdam een enorm gebied van meer dan 12.000 hectare. Het onderhoud van deze uitgestrekte oppervlakte is een complexe uitdaging, zoals Pieter Van Driel, projectmanager bij Idverde—een bedrijf dat al jarenlang verantwoordelijk is voor het onderhoud van de haven—kan bevestigen. “Naast groenonderhoud is het ophalen en ledigen van de afvalcontainers een van de meest tijdrovende taken die we uitvoeren,” legt Pieter uit. Op het haventerrein staan ongeveer 250 afvalbakken, verspreid over het uitgestrekte gebied. “Hoewel we tijdens onze inzamelroutes vaak lege afvalbakken tegenkwamen, willen we absoluut voorkomen dat bakken overvol raken en tot zwerfafval leiden,” voegt hij eraan toe. Deze logistieke uitdaging nam een wending in de lente van 2024, toen Pieter contact opnam met Rebin.",
          rotterdam_text2: "Sinds de samenwerking met Rebin is elke afvalbak in de haven uitgerust met een sensor. Deze sensoren leveren real-time gegevens over het vulniveau van de afvalbakken, waardoor het team precies weet welke afvalbakken moeten worden geledigd en, minstens zo belangrijk, welke niet. Naast inzicht in de vulniveaus heeft ook de planningsmodule op het platform van Rebin aanzienlijke voordelen opgeleverd voor de operaties van Idverde. Pieter legt uit: “Als slechts een paar bakken vol zijn, maar verspreid liggen over tientallen kilometers, zou het verre van efficiënt zijn om ze allemaal in één inzamelroute te gaan ledigen. Door ons inzameling per regio te organiseren, behouden we een gestructureerde aanpak en besparen we aanzienlijk.” Daarnaast maakt het platform van Rebin het mogelijk om routes automatisch te genereren—dagelijks, wekelijks, of naar behoefte—zonder dat er een handmatige actie voor nodig is. Pieter benadrukt het gemak hiervan: “De automatische routeplanning is een enorm handige feature en bespaart ons veel tijd en moeite.”",
          rotterdam_text3: "Een van de aspecten die Pieter het meest waardeert aan Rebin, is de interactie met het team. “Het team van Rebin is altijd toegankelijk en staat steeds open voor feedback, wat hen helpt om continu te innoveren. Dit sluit perfect aan bij onze visie bij Idverde.” Vooruitkijkend onderzoekt Idverde mogelijkheden om het systeem van Rebin in meer projecten toe te passen, om hun activiteiten verder te optimaliseren.",
          rotterdam_quote: "De automatische routeplanning is een enorm handige feature en bespaart ons veel tijd en moeite.",
          rotterdam_summary1: "Rebin heeft 250 afvalbakken in de Haven van Rotterdam uitgerust met sensoren om real-time vulniveaus te gaan monitoren",
          rotterdam_summary2: "Het platform van Rebin automatiseert inzamelroutes per regio, wat tijd en middelen bespaart",
          rotterdam_summary3: "Idverde onderzoekt hoe het systeem in andere projecten kan worden ingezet om hun processen verder te optimaliseren",
        }
      }
    }
  }
})
